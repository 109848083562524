import React from 'react';
import Loadable from 'react-loadable'
import DefaultLayout from './containers/DefaultLayout';
import EditProduct from "./views/Product/EditProduct";
import GoogleOAuthView from "./views/GoogleOauth/GoogleOAuthView";
import GoogleOAuthConfigView from "./views/GoogleOauth/GoogleOAuthConfigView";

function Loading({error}) {
  if (error) {
    return <div className={'text-danger'}>An error occurred while displaying: {error.toString()}</div>
  }
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard/Dashboard'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loading,
});

const Tasks = Loadable({
  loader: () => import('./views/Tasks/Tasks'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./views/Users/User'),
  loading: Loading,
});

const Catalog = Loadable({
  loader: () => import('./views/Product/Catalog/Catalog'),
  loading: Loading,
});

const ProductTemplates = Loadable({
  loader: () => import('./views/Product/Templates'),
  loading: Loading,
});

const OutputRunsPage = Loadable({
  loader: () => import('./views/Retailer/Templates/OutputRunsPage'),
  loading: Loading,
})

const IntegrationTemplates = Loadable({
  loader: () => import('./views/IntegrationTemplates/IntegrationTemplates'),
  loading: Loading,
});

const DataPoints = Loadable({
  loader: () => import('./views/Product/DataPoints/ListDataPoints/ListDataPoints'),
  loading: Loading,
});

const DataPointDetails = Loadable({
  loader: () => import('./views/Product/DataPoints/DataPointDetails/DataPointDetails'),
  loading: Loading,
});

const AddProductTemplate = Loadable({
  loader: () => import('./views/Product/AddTemplate'),
  loading: Loading,
});

const EditProductTemplate = Loadable({
  loader: () => import('./views/Product/EditTemplate/EditTemplate'),
  loading: Loading,
});

const ProductTemplateDataPointConfiguration = Loadable({
  loader: () => import('./views/Product/EditTemplate/DataPointsConfiguration'),
  loading: Loading
});

const ProductTemplateEnrichments = Loadable({
  loader: () => import('./views/Product/EditTemplate/EnrichmentsTab'),
  loading: Loading
})

const DetailedProductViewConfigTab = Loadable({
  loader: () => import('./views/Product/EditTemplate/DetailedProductViewConfigurations'),
  loading: Loading
})

const MeaningsTab = Loadable({
  loader: () => import('./views/Product/EditTemplate/MeaningsTab/MeaningsTab'),
  loading: Loading
})

const FieldDependencyConfig = Loadable({
  loader: () => import('./views/Product/EditTemplate/FieldDependencyConfig/FieldDependencyConfig'),
  loading: Loading
})

const Brands = Loadable({
  loader: () => import('./views/Brand/Brands'),
  loading: Loading,
});

const Brand = Loadable({
  loader: () => import('./views/Brand/Brand'),
  loading: Loading,
});

const ProductMappingConfig = Loadable({
  loader: () => import('./views/Product/ProdMappingConf/ProdMappingConfig'),
  loading: Loading,
});

const RetailerList = Loadable({
  loader: () => import('./views/Retailer/RetailersList/RetailersListPage'),
  loading: Loading,
})

const RetailerView = Loadable({
  loader: () => import('./views/Retailer/RetailerView/RetailerView'),
  loading: Loading
})

const RetailerTemplates = Loadable({
  loader: () => import('./views/Retailer/Templates/RetailerDistributeDataTemplates'),
  loading: Loading,
});

const RetailerDistributeDataTemplateMappingConfig = Loadable({
  loader: () => import('./views/Retailer/TemplateMappingConfig/TemplateMappingConfig'),
  loading: Loading,
});

const OrderConfirmationBatch = Loadable({
  loader: () => import('./views/Tasks/OrderConfirmation/OrderConfirmationBatchDisplay'),
  loading: Loading,
});

const RetailerDistributeDataTemplate = Loadable({
  loader: () => import('./views/Retailer/DistributeDataTemplate/DistributeDataTemplate'),
  loading: Loading,
});


const UniqueAttributes = Loadable({
  loader: () => import('./views/Product/UniqueAttributes/UniqueAttributes'),
  loading: Loading,
});

const UniqueAttribute = Loadable({
  loader: () => import('./views/Product/UniqueAttributes/UniqueAttribute'),
  loading: Loading,
});

const UniqueValues = Loadable({
  loader: () => import('./views/UniqueValues/UniqueValuesListView'),
  loading: Loading,
})

const UniqueValueSet = Loadable({
  loader: () => import('./views/UniqueValues/UniqueValueSetDetailsView'),
  loading: Loading,
})

const CompleteMissingValues = Loadable({
  loader: () => import('./views/ImportRuns/CompleteMissingValuesPage'),
  loading: Loading
})

const ManualInputHints = Loadable({
  loader: () => import('./views/ManualInputHints/ManualInputHintsPage'),
  loading: Loading
})

const IngestedFiles = Loadable({
  loader: () => import('./views/IngestedFiles/IngestedFileListView'),
  loading: Loading,
})

const SingleIngestedFile = Loadable({
  loader: () => import('./views/IngestedFiles/SingleIngestedFileView'),
  loading: Loading,
})

const IngestionChannels = Loadable({
  loader: () => import('./views/IngestionChannels/IngestionChannelListView'),
  loading: Loading
})

const IngestionRunPage = Loadable({
  loader: () => import('./views/IngestionRun/IngestionRunPage'),
  loading: Loading
})

const CreateIngestionChannel = Loadable({
  loader: () => import('./views/IngestionChannels/CreateIngestionChannelView'),
  loading: Loading
});

const ViewIngestionChannel = Loadable({
  loader: () => import("./views/IngestionChannels/IngestionChannelView"),
  loading: Loading
});

const ViewIngestionChannelRuns = Loadable({
  loader: () => import("./views/IngestionChannels/IngestionChannelRunsView"),
  loading: Loading
})

const ViewIngestionChannelReprocessTriggers = Loadable({
  loader: () => import("./views/IngestionChannels/IngestionChannelAutomaticReprocessingView"),
  loading: Loading
})

const ViewIngestionChannelTriggers = Loadable({
  loader: () => import("./views/IngestionChannels/IngestionChannelTriggersView"),
  loading: Loading
})

const ViewIngestionChannelOutputTriggersView = Loadable({
  loader: () => import("./views/IngestionChannels/IngestionChannelOutputTriggersView"),
  loading: Loading
})

const InputTemplateListView = Loadable({
  loader: () => import("./views/inputTemplates/InputTemplateListView"),
  loading: Loading
})


const InputFlowListView = Loadable({
  loader: () => import("./views/InputFlows/InputFlowListView"),
  loading: Loading,
})

const OrderListView = Loadable({
  loader: () => import('./views/Orders/OrderList/OrderListView'),
  loading: Loading
})

const OrderSharingPolicies = Loadable({
  loader: () => import('./views/OrderSharingPolicies/OrderSharingPoliciesList'),
  loading: Loading
})

const OrderSharingPolicyPage = Loadable({
  loader: () => import('./views/OrderSharingPolicies/OrderSharingPolicyPage'),
  loading: Loading
})

const OCParsers = Loadable({
  loader: () => import('./views/OCParsers/OCParsers'),
  loading: Loading
})

const OrderDetailsView = Loadable({
  loader: () => import('./views/Orders/OrderView/OrderView'),
  loading: Loading
})

const DataPullListView = Loadable({
  loader: () => import('./views/DataPulls/DataPullList'),
  loading: Loading
})

const DataPullDetailedView = Loadable({
  loader: () => import('./views/DataPulls/DataPullDetailedView'),
  loading: Loading
})

const DataPullRunDetailedView = Loadable({
  loader: () => import('./views/DataPullRuns/DataPullRunDetailsView'),
  loading: Loading,
})

const WebhookListView = Loadable({
  loader: () => import('./views/Webhooks/WebhookList'),
  loading: Loading
})

const WebhookDetailedView = Loadable({
  loader: () => import('./views/Webhooks/WebhookDetailedView'),
  loading: Loading
})

const PipelineListView = Loadable({
  loader: () => import('./views/Pipelines/PipelineListView'),
  loading: Loading
})

const TaskQueueStatsPage = Loadable({
  loader: () => import('./views/TaskQueueStats/TaskQueueStatsPage'),
  loading: Loading,
})

const EntityLog = Loadable({
  loader: () => import('./views/EntityLog/EntityLogPage'),
  loading: Loading,
})

const DevSnapshots = Loadable({
  loader: () => import('./views/DevSnapshots/DevSnapshotsPage'),
  loading: Loading
})

const AmazonMarketplace = Loadable({
  loader: () => import('./views/Amazon/AmazonMarketplaces'),
  loading: Loading
})

const BolMarketplaces = Loadable({
  loader: () => import('./views/Bol/BolMarketplaces'),
  loading: Loading
})

const BolMarketplaceView = Loadable({
  loader: () => import('./views/Bol/BolMarketplaceView'),
  loading: Loading
})

const BolProductTypeView = Loadable({
  loader: () => import('./views/Bol/BolProductTypeView'),
  loading: Loading,
})


const EdiMain = Loadable({
  loader: () => import('./views/edi/EdiMain'),
  loading: Loading,
})

const EdiConfiguration = Loadable({
  loader: () => import('./views/edi/EdiConfiguration'),
  loading: Loading,
})


const EdiMessages = Loadable({
  loader: () => import('./views/edi/EdiMessages'),
  loading: Loading,
})

const DirectOutputsListPage = Loadable({
  loader: () => import('./views/DirectOutputsList/DirectOutputListPage'),
  loading: Loading,
})


const DirectOutputDetailsPage = Loadable({
  loader: () => import('./views/DirectOutputDetails/DirectOutputDetailsPage'),
  loading: Loading,
})

const ProductStoresManagePage = Loadable({
  loader: () => import('./views/ProductStoresManage/ProductStoresManagePage'),
  loading: Loading,
})

const ProductStoresSearchPage = Loadable({
  loader: () => import('./views/ProductStoresManage/ProductStoresSearchPage'),
  loading: Loading,
})

const ProductStoresViewPage = Loadable({
  loader: () => import('./views/ProductStoresManage/ProductStoresView'),
  loading: Loading,
})

const ProductStoresProducts = Loadable({
  loader: () => import('./views/ProductStoresManage/ProductStoresProducts'),
  loading: Loading,
})

const ProductStoresCollectionsPage = Loadable({
  loader: () => import('./views/ProductStoresManage/ProductStoresCollectionsPage'),
  loading: Loading,
})


const BrandsOverviewPage = Loadable({
  loader: () => import('./views/UsersOverview/BrandsOverview/BrandsOverviewPage'),
  loading: Loading
})

const RetailersOverviewPage = Loadable({
  loader: () => import('./views/UsersOverview/RetailersOverview/RetailersOverviewPage'),
  loading: Loading
})

const MagasinFtpProcessingPage = Loadable({
  loader: () => import('./views/MagasinFtpProcessing/MagasinFtpProcessingPage'),
  loading: Loading,
})


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {path: '/', exact: true, name: 'Home', component: DefaultLayout},
  {path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard},
  {path: '/users/:id', exact: true, name: 'User details', component: User},
  {path: '/users', exact: true, name: 'Users', component: Users},
  {path: '/tasks', exact: true, name: 'Tasks', component: Tasks},
  {path: '/tasks/:tab', exact: true, name: 'Tasks', component: Tasks},
  {path: '/product', exact: true, name: 'Product', component: Catalog},
  {path: '/product/catalog', exact: true, name: 'Catalog', component: Catalog},
  //{path: '/product/create', exact: true, name: 'Create new product', component: CreateProduct},
  {path: '/product/catalog/editProduct/:productId/', exact: true, name: 'Product', component: EditProduct},
  {path: '/product/catalog/editProduct/:productId/:groupPath', name: "", exact: true, component: EditProduct},
  {path: '/product/templates', exact: true, name: 'RetailerDistributeDataTemplates', component: ProductTemplates},
  {path: '/retailer/integrations', exact: true, name: 'IntegrationTemplates', component: IntegrationTemplates},
  {path: '/product/data-points/:id', exact: true, name: 'Data Point Details', component: DataPointDetails},
  {path: '/product/data-points', exact: true, name: 'Data Points', component: DataPoints},
  {path: '/product/uniqueattributes', exact: true, name: 'Unique Attributes', component: UniqueAttributes},
  {path: '/product/uniqueattributes/:id', exact: true, name: 'Unique Attribute', component: UniqueAttribute},
  {path: '/system/unique-values/', exact: true, name: 'Unique Value Sets', component: UniqueValues},
  {path: '/system/unique-values/:id/:tab', exact: true, name: 'Unique Value Set Details', component: UniqueValueSet},
  {path: '/system/ingestion-channels', exact: true, name: 'Ingestion channels', component: IngestionChannels},
  {path: '/system/ingestion-run/:id', exact: true, name: 'Ingestion run', component: IngestionRunPage},
  {path: '/system/amazon/marketplaces', exact: true, name: 'Amazon Marketplace', component: AmazonMarketplace},
  {
    path: '/system/amazon/marketplaces/:marketplaceId',
    exact: true,
    name: 'Amazon Marketplace',
    component: AmazonMarketplace
  },
  {
    path: '/system/amazon/marketplaces/:marketplaceId/:templateId',
    exact: true,
    name: 'Amazon Marketplace',
    component: AmazonMarketplace
  },
  {path: '/system/bol/marketplaces/', exact: true, name: 'Bol.com Marketplace', component: BolMarketplaces},
  {
    path: '/system/bol/marketplaces/:country',
    exact: true,
    name: 'Bol.com Language Marketplace',
    component: BolMarketplaceView
  },
  {
    path: '/system/bol/marketplaces/:country/:chunkId',
    exact: true,
    name: 'Bol.com Chunk',
    component: BolProductTypeView
  },

  {
    path: '/system/ingestion-channels/create',
    exact: true,
    name: 'Create ingestion channel',
    component: CreateIngestionChannel
  },
  {
    path: '/system/ingestion-channels/:id',
    exact: true,
    name: 'Ingestion channel view',
    component: ViewIngestionChannel
  },
  {
    path: '/system/ingestion-channels/:id/runs',
    exact: true,
    name: 'Ingestion channel runs',
    component: ViewIngestionChannelRuns
  },
  {
    path: '/system/ingestion-channels/:id/automatic-reprocessing',
    exact: true,
    name: 'Ingestion channel automatic reprocessing',
    component: ViewIngestionChannelReprocessTriggers,
  },
  {
    path: '/system/ingestion-channels/:id/triggers',
    exact: true,
    name: 'Ingestion channel triggers',
    component: ViewIngestionChannelTriggers
  }, {
    path: '/system/ingestion-channels/:id/output-triggers',
    exact: true,
    name: 'Ingestion channel triggers',
    component: ViewIngestionChannelOutputTriggersView
  },
  {path: '/input/input-flows', exact: true, name: 'Input flows', component: InputFlowListView},
  {path: '/system/ingested-files', exact: true, name: 'Ingested files', component: IngestedFiles},
  {path: '/system/ingested-files/:id', exact: true, name: 'Ingested file', component: SingleIngestedFile,},
  {path: '/system/data-pulls', exact: true, name: 'Data pulls', component: DataPullListView,},
  {path: '/system/data-pulls/:id', exact: true, name: 'Data pull', component: DataPullDetailedView,},
  {path: '/system/data-pulls/:id/:tab', exact: true, name: 'Data pull', component: DataPullDetailedView,},
  {path: '/system/data-pull-runs/:id', exact: true, name: 'Data pull run', component: DataPullRunDetailedView,},
  {path: '/system/webhooks', exact: true, name: 'Webhooks', component: WebhookListView,},
  {path: '/system/webhooks/:id', exact: true, name: 'Webhook', component: WebhookDetailedView,},
  {path: '/system/webhooks/:id/:tab', exact: true, name: 'Webhook', component: WebhookDetailedView,},
  {path: '/product/templates/addTemplate', exact: true, name: 'Add Template', component: AddProductTemplate},
  {
    path: '/product/templates/editTemplate/:id',
    exact: true,
    name: 'Edit Template',
    component: EditProductTemplate
  },
  {
    path: '/product/templates/editTemplate/:id/data-points',
    exact: true,
    name: 'Configure data points',
    component: ProductTemplateDataPointConfiguration
  },
  {
    path: '/product/templates/editTemplate/:id/enrichments',
    exact: true,
    name: 'Enrichments',
    component: ProductTemplateEnrichments
  },
  {
    path: '/product/templates/editTemplate/:id/detailed-product-view-configurations',
    exact: true,
    name: 'DetailedProductViewConfigurations',
    component: DetailedProductViewConfigTab
  },
  {
    path: '/product/templates/editTemplate/:id/meanings',
    exact: true,
    name: 'Meanings',
    component: MeaningsTab
  },
  {
    path: '/product/templates/editTemplate/:id/fieldDependencyConfig',
    exact: true,
    name: 'Field Dependency Config',
    component: FieldDependencyConfig
  },
  {
    path: '/product/prodMappingConfigs/:id',
    exact: true,
    name: 'Product Data Mappings',
    component: ProductMappingConfig
  },

  {path: '/brands', exact: true, name: 'Brands', component: Brands},
  {path: '/brands/:id/:tab/:entityId', exact: true, name: 'Import runs', component: Brand},
  {path: '/brands/:id/:tab', exact: true, name: 'Import runs', component: Brand},
  {path: '/brands/:id', exact: true, name: 'Brand details', component: Brand},


  {path: '/retailers/', exact: true, name: 'Retailers', component: RetailerList},
  {path: '/retailers/:id', exact: true, name: 'Retailer', component: RetailerView},
  {path: '/retailers/:id/:tab', exact: true, name: 'Retailer', component: RetailerView},
  {path: '/retailer/templates', exact: true, name: 'Distribute Data Templates', component: RetailerTemplates},
  {
    path: '/retailer/templates/:id',
    exact: true,
    name: 'Distribute Data Template',
    component: RetailerDistributeDataTemplate
  },
  {path: '/retailer/templates/:id/runs', exact: true, name: 'Output runs', component: OutputRunsPage},

  {
    path: '/retailer/templates/:id/:tab',
    exact: true,
    name: 'Distribute Data Template',
    component: RetailerDistributeDataTemplate
  },
  {
    path: '/retailer/templateMappingConfigs/:id',
    exact: true,
    name: 'Distribute Data Mapping',
    component: RetailerDistributeDataTemplateMappingConfig
  },
  {
    path: '/order-confirmation/batches/:id',
    exact: true,
    name: 'Order Confirmation Batch',
    component: OrderConfirmationBatch
  },

  {path: '/import-runs/:id/complete-missing-values', name: 'Complete missing values', component: CompleteMissingValues},
  {path: '/manual-input-hints/:tab', name: 'Manual input hints', component: ManualInputHints},
  {path: '/manual-input-hints', name: 'Manual input hints', component: ManualInputHints},
  {path: '/input/templates', name: 'Input templates', component: InputTemplateListView},
  {path: '/orders', exact: true, name: 'Orders', component: OrderListView},
  {path: '/parsers', exact: true, name: 'OCParsers', component: OCParsers},
  {path: '/orders/:id', exact: true, name: 'Order', component: OrderDetailsView},
  {path: '/order-sharing-policies', exact: true, name: 'Order Sharing Policies', component: OrderSharingPolicies},
  {path: '/order-sharing-policies/:id', exact: true, name: 'Order Sharing Policy', component: OrderSharingPolicyPage},
  {path: '/pipelines', exact: true, name: 'Pipelines', component: PipelineListView},
  {path: '/system/task-queues', exact: true, name: 'Task queues', component: TaskQueueStatsPage},
  {path: '/system/entity-log/:mainId', exact: true, name: 'EntityLog', component: EntityLog},
  {path: '/system/dev-snapshots', exact: true, name: 'EntityLog', component: DevSnapshots},
  {path: '/edi', exact: true, name: 'EDI', component: EdiMain},
  {path: '/edi/configuration', exact: true, name: 'EDI', component: EdiConfiguration},
  {path: '/edi/messages', exact: true, name: 'EDI', component: EdiMessages},
  {path: '/system/direct-outputs', exact: true, name: 'Direct outputs', component: DirectOutputsListPage},
  {path: '/system/direct-outputs/:id', exact: true, name: 'Direct outputs', component: DirectOutputDetailsPage},
  {
    path: '/system/product-stores/manage',
    exact: true,
    name: 'Manage Product Stores',
    component: ProductStoresManagePage
  },
  {
    path: '/system/product-stores/search',
    exact: true,
    name: 'Search in Product Stores',
    component: ProductStoresSearchPage
  },
  {path: '/system/product-stores/:brandId', exact: true, name: 'Product Store', component: ProductStoresViewPage},
  {
    path: '/system/product-stores/:storeId/products/page/:page',
    exact: true,
    name: 'Product Store',
    component: ProductStoresProducts
  },
  {
    path: '/system/product-stores/:store/collections/',
    exact: true,
    name: 'Product Store Collections',
    component: ProductStoresCollectionsPage
  },
  {
    path: '/overview/brands',
    exact: true,
    name: 'Brands Overview',
    component: BrandsOverviewPage
  },
  {
    path: '/overview/retailers',
    exact: true,
    name: 'Retailers Overview',
    component: RetailersOverviewPage
  },

  {path: '/system/integrations/google', exact: true, name: 'Google Drive', component: GoogleOAuthConfigView},
  {path: '/oauth/callback/google', exact: true, name: 'Google Oauth Callback', component: GoogleOAuthView},
  {path: '/system/magasin-ftp-images', exact: true, name: 'Magasin', component: MagasinFtpProcessingPage},
];

export default routes;
